<template>
  <div class="transfer-stocks">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách phiếu chuyển kho'">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="reportClick">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-if="checkPermission('STOCK_INSERT')" class="ml-3">
                <router-link to="/transfer-stocks/add-transfer-stock">
                  <b-button
                    v-bind:style="btnCreate"
                    variant="primary"
                    size="sm"
                  >
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                    mới
                  </b-button>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control-sm"
                    :config="dpConfigs.start"
                    v-model="dpForm.startDate"
                  ></date-picker>
                  <span class="mr-1 ml-1"></span>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control-sm"
                    :config="dpConfigs.end"
                    v-model="dpForm.endDate"
                  ></date-picker>
                </div>
              </b-col>
              <b-col>
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  placeholder="mã, tên, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProduct"
                  @change="onInputChangeProduct"
                  suggestionName="productName"
                />
              </b-col>
              <b-col>
                <Autosuggest
                  :model="searchStock"
                  :suggestions="filteredOptions"
                  placeholder="kho nguồn"
                  :limit="10"
                  @select="onSelected"
                  @change="onInputChange"
                  suggestionName="suggestionName"
                />
              </b-col>
              <b-col>
                <Autosuggest
                  :model="searchDestinationStock"
                  :suggestions="filteredDStockOptions"
                  placeholder="kho đích"
                  :limit="10"
                  @select="onDestinationSelected"
                  @change="onSearchDestinationChange"
                  suggestionName="suggestionName"
                />
              </b-col>
              <b-col>
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="Tìm kiếm theo nội dung"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form-input
                  v-model="searchId"
                  type="text"
                  placeholder="Mã phiếu"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="stocks"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <div
                  class="productCode"
                  @click="editItem(row.item)"
                  style="cursor: pointer"
                >
                  <span v-text="row.item.code"></span>
                </div>
              </template>
              <template v-slot:cell(requireStockCode)="row">
                <div
                  class="productCode"
                  @click="linkToRequireStock(row.item)"
                  style="cursor: pointer"
                >
                  <span v-text="row.item.requireStockCode"></span>
                </div>
              </template>

              <template v-slot:cell(importDate)="row">
                <div style="text-align: center">
                  <span
                    v-text="row.item.importDate"
                    class="text-right text-primary mb-0"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(sourceWarehouseId)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.sourceWarehouseId"></span>
                </div>
              </template>

              <template v-slot:cell(destinationWarehouseId)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.destinationWarehouseId"></span>
                </div>
              </template>
              <template v-slot:cell(description)="row">
                <div
                  @mouseover="hoverPrice = true"
                  @mouseleave="hoverPrice = false"
                >
                  <span v-text="row.item.description" class="mr-2"></span>
                  <span @click="handleShowNoteModel(row.item)">
                    <i v-if="hoverPrice" class="fas fa-edit"></i>
                  </span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="printItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-printer"
                        ></i>
                        &nbsp; In phiếu
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('STOCK_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số phiếu:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchStock"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
            <template>
              <b-modal
                v-model="showEditPriceModal"
                hide-footer
                :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput" for="inputOriginalPrice"
                      >Ghi chú</label
                    >
                    <b-form-textarea
                      v-model="note"
                      placeholder="Nhập ghi chú..."
                      rows="5"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="updateNote"
                      >Lưu</b-button
                    >
                    <b-button
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      @click="handleShowNoteModel"
                      >Hủy</b-button
                    >
                  </b-col>
                </b-row>
              </b-modal>
            </template>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { TRANSFER_STOCK } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchDestinationStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: TRANSFER_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu chuyển kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'requireStockCode',
          label: 'Mã phiếu yêu cầu XNK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },

        {
          key: 'sourceWarehouseId',
          label: 'Kho nguồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'destinationWarehouseId',
          label: 'Kho đích',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'importDate',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      selectedDStock: '',
      optionsDStock: [
        {
          data: [],
        },
      ],
      filteredDStockOptions: [],
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
    };
  },
  methods: {
    ...getToastInstance(),
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [...this.options[0].data];
        this.filteredDStockOptions = [...this.options[0].data];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      let params = {
        page: this.page,
        pageSize: 10,
        search: this.search ? this.search.trim() : '',
        searchCode: this.searchId ? this.searchId.trim() : '',
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        searchStock: this.searchStock,
        searchDestinationStock: this.searchDestinationStock,
        type: this.type,
        searchProduct: this.searchProduct,
      };

      ApiService.setHeader();
      ApiService.query('stocks', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          let result = [];
          stockResponse.forEach((item, index) => {
            let stock = {
              ...item,
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              status: this.status,
              importDate: moment(String(item.importDate)).format('DD/MM/YYYY'),
              exportDate: moment(String(item.exportDate)).format('DD/MM/YYYY'),
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.id },
      });
    },
    linkToRequireStock: function (item) {
      this.$router.push({
        name: 'list-require-stock',
        query: { code: item.requireStockCode },
      });
    },
    onDestinationSelected(option) {
      this.selectedDStock = option.item.name;
      this.searchDestinationStock = option.item.name;
    },
    onSearchDestinationChange(text) {
      if (!text) {
        text = '';
      }
      this.searchDestinationStock = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredDStockOptions = [...filteredData];
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu chuyển kho!',
        text: 'Bạn có chắc muốn xóa phiếu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/transfer/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        this.fetchStock();
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-transfer-stock',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    reportClick: async function () {
      let searchDesciption = this.search;
      let searchCode = this.searchId;
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchProduct = this.searchProduct;
      let baseUrl = BASE_URL;
      let url = `${baseUrl}stocks/export-excel-stock-slip?type=${this.type}&searchProduct=${searchProduct}
      &searchDesciption=${searchDesciption}&searchCode=${searchCode}
      &searchFromDay=${searchFromDay}&searchToDay=${searchToDay}
      &searchStock=${this.searchStock}&searchDestinationStock=${this.searchDestinationStock}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: function(progressEvent) {
          console.log(progressEvent);
        }
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
        console.log(fileName);
      });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      let data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.setHeader();
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-transfer-stockslip',
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chuyển kho', route: 'transfer-stocks' },
      { title: 'Danh sách phiếu chuyển kho' },
    ]);
  },
  created() {
    this.fetchStock();
    this.fetchStore();
  },
  components: {
    KTCodePreview,
    Autosuggest,
    VclTable,
  },
};
</script>

<style lang="scss">
.transfer-stocks {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }
 
  .productCode:hover {
    text-decoration: underline;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
